import quarterRange from './quarter-range';

export default function recentQuarters() {
  let periods = [];
  let currentYear = new Date().getFullYear();

  [currentYear, currentYear - 1, currentYear - 2].forEach((year) => {
    for (let i = 4; i > 0; i--) {
      const range = quarterRange(year, i);
      periods.push({
        startDate: range[0],
        endDate: range[1],
        year,
        quarter: i,
      });
    }
  });

  let currentIndex = periods.findIndex((quarter) => {
    const now = new Date();
    return now >= quarter.startDate && now <= quarter.endDate;
  });

  return periods.slice(currentIndex, currentIndex + 4);
}
